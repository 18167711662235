<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title" style="margin-bottom: -36px">
            Journal Entry
            <v-spacer></v-spacer>

            <add-button
              permission="section-create"
              @action="$router.push({ name: 'entry-list' })"
            >
              Journal Entry Book
            </add-button>
          </v-card-title>

          <v-card-text>
            <v-form
              ref="form"
              @submit.prevent="store"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="store"
              lazy-validation
            >
              <v-layout wrap>
                <v-flex xs12 sm4>
                  <v-select
                    v-model="form.journal_type_id"
                    :items="journalTypes"
                    label="Select Journal Voucher type"
                    name="journal_type_id"
                    :height="25"
                    :readonly="form.loading"
                    :error-messages="form.errors.get('journal_type_id')"
                    @change="form.errors.clear('journal_type_id')"
                    :disabled="updateData"
                  />
                  <span
                    v-if="form.journal_type_id"
                    style="position: absolute;margin-top: -18px;font-size: 12px; color: green"
                  >
                    <span v-if="manualSetting"
                      >Voucher No should be entered manually.</span
                    >
                    <span v-else>Voucher No generate Automatically.</span>
                  </span>
                </v-flex>
                <v-flex xs12 sm2>
                  <span v-if="!updateData || manualSetting">
                    <span v-if="manualSetting">
                      <v-text-field
                        label="Voucher No"
                        v-model="form.voucher_no"
                        name="voucher_no"
                        :error-messages="form.errors.get('voucher_no')"
                        :height="25"
                        :readonly="form.loading"
                      />
                    </span>
                    <span v-else class="voucherNo">
                      {{
                        automaticVoucherNo ? "V.No: " + automaticVoucherNo : ""
                      }}</span
                    >
                  </span>
                </v-flex>

                <v-flex xs12 sm4>
                  <v-text-field
                    label="Date"
                    v-model="form.entry_date"
                    name="entry_date"
                    prepend-icon="event"
                    mask="####-##-##"
                    placeholder="YYYY-MM-DD"
                    :error-messages="form.errors.get('entry_date')"
                    :height="25"
                    :readonly="form.loading"
                  />
                </v-flex>
                <v-flex>
                  <v-card-title style="margin-top: -15px;margin-bottom: -10px;">
                    <span class="drcr" v-html="drcrAmount()"></span>
                    <v-spacer></v-spacer>
                    <v-btn
                      :disabled="form.loading"
                      @click="addRow"
                      class="success"
                      outline
                      icon
                      small
                      style="margin-right: 77px"
                    >
                      <v-icon small class="success--text">add</v-icon>
                    </v-btn>
                  </v-card-title>
                </v-flex>
                <v-layout
                  column
                  class="rowRap"
                  :class="form.particulars.length > 4 ? 'fheight' : ''"
                >
                  <v-flex md12 style="overflow: auto">
                    <v-layout
                      row
                      wrap
                      v-for="(row, key) in form.particulars"
                      :key="key"
                    >
                      <v-flex xs12 sm4>
                        <v-autocomplete
                          v-model="form.particulars[key].ledger_head_id"
                          :items="itemsLedger[key]"
                          @focus="searchKey = key"
                          @keyup="ledgerHeadSearch"
                          @change="closingBalance(key)"
                          hide-no-data
                          item-text="name"
                          item-value="id"
                          label="Select Ledger Head"
                          placeholder="Start typing to Search"
                          height="25"
                        ></v-autocomplete>
                        <span class="balance">
                          {{
                            form.particulars[key].ledger_head_id
                              ? "Balanace: " +
                                Math.abs(
                                  form.particulars[key].opening_balance
                                ).currency()
                              : ""
                          }}
                          {{
                            form.particulars[key].ledger_head_id
                              ? form.particulars[key].opening_balance > 0
                                ? "Dr"
                                : "Cr"
                              : ""
                          }}
                        </span>
                      </v-flex>
                      <v-flex xs12 sm2>
                        <v-select
                          v-model="row.drcr"
                          :items="drcrList"
                          label="DR/CR"
                          name="drcr"
                          :height="25"
                          :readonly="form.loading"
                        />
                      </v-flex>
                      <v-flex xs12 sm4>
                        <v-text-field
                          :readonly="form.loading"
                          v-model="row.amount"
                          type="number"
                          name="amount"
                          label="Amount"
                          @keyup="addRowData"
                          :height="25"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs12 sm1 text-xs-right>
                        <v-btn
                          :disabled="form.particulars.length < 2"
                          @click="removeElement(row)"
                          class="error"
                          outline
                          icon
                          small
                        >
                          <v-icon small class="error--text">remove</v-icon>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-flex xs12>
                  <v-textarea
                    :readonly="form.loading"
                    v-model="form.narration"
                    label="Narration"
                    name="narration"
                    :error-messages="form.errors.get('narration')"
                  />
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              slot="activator"
              color="primary"
              dark
              outline
              @click="ledgerForm.dialog = true"
              :disabled="form.loading"
            >
              <v-icon small>add</v-icon> &nbsp;Ledger Head
            </v-btn>
            <v-spacer />
            <v-btn
              class="text-xs-right"
              color="secondary"
              :disabled="form.errors.any() || form.loading || storeData"
              @click="store(1)"
              outline
              >Save
            </v-btn>
            <v-btn
              class="text-xs-right"
              color="secondary"
              :disabled="form.errors.any() || form.loading || storeData"
              @click="store(0)"
              outline
              >Save And Continue
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- Add Ledger Head -->
        <v-dialog v-model="ledgerForm.dialog" persistent max-width="500px">
          <v-card>
            <v-card-title class="title primary white--text"
              >Account Group Information</v-card-title
            >
            <v-card-text>
              <v-form
                ref="form"
                @submit.prevent="save"
                @keydown.native="form.errors.clear($event.target.name)"
                @keyup.enter="save"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-layout row wrap>
                    <v-layout wrap>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="ledgerForm.name"
                          name="name"
                          label="Name"
                          :height="25"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm6 d-flex>
                        <v-autocomplete
                          v-model="ledgerForm.account_head_id"
                          :items="itemsAccount"
                          :search-input.sync="searchA"
                          hide-no-data
                          item-text="Name"
                          item-value="id"
                          label="Account Group"
                          placeholder="Start typing to Search"
                          height="25"
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12 sm6 d-flex>
                        <v-select
                          v-model="ledgerForm.drcr"
                          name="drcr"
                          :items="drcr"
                          label="Select Opening Balance DR/CR"
                          :height="25"
                        />
                      </v-flex>
                      <v-flex xs12 sm6>
                        <v-text-field
                          v-model="ledgerForm.opening_balance"
                          name="opening_balance"
                          label="Opening Balance"
                          :height="25"
                          type="number"
                          autocomplete="off"
                        />
                      </v-flex>
                      <v-flex xs12 sm12>
                        <v-textarea
                          v-model="ledgerForm.description"
                          name="description"
                          label="Description"
                          autocomplete="off"
                        />
                      </v-flex>
                    </v-layout>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                dark
                outline
                @click="accountGroupFormDialog = true"
                :disabled="accountGroupForm.loading"
              >
                <v-icon small>add</v-icon> &nbsp;Account Group
              </v-btn>
              <!--<v-btn color="success" flat @click="accountGroupFormDialog = true" outline> add Account Group</v-btn>-->
              <v-spacer />
              <v-btn
                color="warning"
                flat
                @click="ledgerForm.dialog = false"
                outline
                >Cancel</v-btn
              >
              <v-btn color="success" flat @click="storeLedger" outline
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="accountGroupFormDialog" persistent max-width="400px">
          <v-card>
            <v-card-title class="title primary white--text"
              >Account Head Information</v-card-title
            >
            <v-card-text>
              <v-form
                ref="form"
                @submit.prevent="accountGroupSave"
                @keyup.enter="accountGroupSave"
                lazy-validation
              >
                <v-container grid-list-md>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="accountGroupForm.name"
                        label="Name"
                        :error-messages="form.errors.get('name')"
                        :height="25"
                        autocomplete="off"
                      />
                    </v-flex>
                    <v-autocomplete
                      v-model="accountGroupForm.parent_id"
                      :items="itemsAccount"
                      :search-input.sync="searchAG"
                      hide-no-data
                      item-text="Name"
                      item-value="id"
                      label="Account Group"
                      placeholder="Start typing to Search"
                      height="25"
                    ></v-autocomplete>
                    <v-flex xs12 sm12>
                      <v-textarea
                        name="description"
                        :error-messages="form.errors.get('description')"
                        v-model="accountGroupForm.description"
                        label="Description"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="warning"
                flat
                @click="
                  (accountGroupFormDialog = false), accountGroupForm.reset()
                "
                outline
                >Cancel
              </v-btn>
              <v-btn color="success" flat @click="accountGroupSave" outline
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!--<notification :notification="form.notification"/>-->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import data from "./modules/data";
import computed from "./modules/computed";
import mounted from "./modules/mounted";
import created from "./modules/created";
import methods from "./modules/methods";
import watch from "./modules/watch";

// import Spinner from '@/components/Partials/Spinner'
// import Notification from '@/components/Partials/Notification'

export default {
  name: "user",
  // components: {Spinner, Notification},
  data: () => data,
  mounted: mounted,
  computed: computed,
  methods: methods,
  created: created,
  watch: watch,
};
</script>
<style lang="scss" scoped>
.drcr {
  margin-left: -7px;
}

.voucherNo {
  position: absolute;
  margin-top: 25px;
  color: green;
}

.fheight {
  height: 45vh;
}

.rowRap {
  margin-left: 10px !important;
  margin-right: 75px !important;
  padding: 15px 15px;
  border: 1px solid black;
}

.v-input.v-text-field.v-text-field--placeholder.v-select.v-select--is-menu-active.v-autocomplete.v-input--is-focused.theme--light.primary--text {
  margin-bottom: -22px !important;
}

.v-input.v-text-field.v-text-field--placeholder.v-select.v-autocomplete.v-input--is-label-active.v-input--is-dirty.theme--light {
  margin-bottom: -22px;
}
.v-input.v-text-field.v-text-field--placeholder.v-select.v-autocomplete.theme--light {
  margin-bottom: -22px;
}

.balance {
  color: #777;
  font-size: 11px;
}
</style>
