export default {
    //     editItem(data) {
    //         this.form.edit(data);
    //         // console.log(data.particulars[0].cr)
    //         let i = 0;
    //         for (i = 0; i < data.particulars.length; i++) {
    //             if (data.particulars[i].dr > 0) {
    //                 data.particulars[i].drcr = 'dr'
    //                 data.particulars[i].amount = data.particulars[i].dr
    //             }
    //
    //             if (data.particulars[i].cr > 0) {
    //                 data.particulars[i].drcr = 'cr'
    //                 data.particulars[i].amount = data.particulars[i].cr
    //             }
    //         }
    //         // console.log(data.particulars);
    //         //  this.form.dialog = true;
    // //        this.form.particulars = [{ledger_head_id: null, drcr: 'dr', amount: 0}];
    //
    //     },

    deleteItem(journalEntry) {
        this.form.delete(journalEntry.id);
    },

    store(value) {

        if (this.manualSetting && this.form.voucher_no == '') {
            this.$events.fire('notification', { message: 'Voucher No is required', status: 'error' });
        } else {

            if (this.form.entry_date.length === 8) {
                this.form.entry_date = this.form.entry_date.substr(0, 4) + '-' + this.form.entry_date[4] + this.form.entry_date[5] + '-' + this.form.entry_date.slice(-2);
            }
            if (!this.form.particulars[0].ledger_head_id || this.form.particulars[0].amount <= 0) {
                this.$events.fire('notification', { message: 'Please fill the particulars correctly', status: 'error' });
            } else {
                let particularEnd = this.form.particulars[this.form.particulars.length - 1];
                if (particularEnd.amount <= 0) this.form.particulars.pop();
                this.form.store().then(({ data }) => {
                    if (value == 1) {
                        this.$router.push({ name: 'entry-list' });
                    }
                }).catch(e => {
                    this.$events.fire('notification', { message: e.data.message, status: 'error' });
                });
            }
        }

    },

    storeLedger() {
        // if (!this.ledgerForm.account_head_id) this.ledgerForm.account_head_id = this.ledgerForm.account_head_id.id;
        this.ledgerForm.store().then(({ data }) => {
            if (data.code === 201) {
                let len = this.form.particulars.length;
                if (['', null, undefined].includes(this.form.particulars[len - 1].ledger_head_id)) {
                    this.itemsLedger[len - 1] = [];
                    this.itemsLedger[len - 1].push({ name: data.data.name, id: data.data.id });
                    this.form.particulars[len - 1].ledger_head_id = data.data.id;
                }

                // this.getLedgerHeads(data.data.id);
            }
        })
    },

    getJournalTypes() {
        this.$rest.get('/api/journal-type').then(({ data }) => {
            this.journalTypes = data.data.filter(item => item.pr_type === 'journal').map(item => {
                return { value: item.id, text: item.name, setting: item.setting.voucher_numbering }
            });
        })
    },

    getAccountHeads() {
        this.$rest.get('/api/account-head').then(({ data }) => {
            this.accountHeads = data.data.map(item => ({ value: item.id, text: item.name }));
        })
    },

    getLedgerHeads(id) {
        this.$rest.get('/api/ledger-head').then(({ data }) => {
            this.ledgerHeads = data.data.map(item => ({ value: item.id, text: item.name }));
            if (![undefined, null].includes(id)) {
                let len = this.form.particulars.length;
                if (len > 0) {
                    if (['', null, undefined].includes(this.form.particulars[len - 1].ledger_head_id)) {
                        this.form.particulars[len - 1].ledger_head_id = id;
                    }
                }
            }
        })
    },


    addRow: function () {
        if ([undefined, null].includes(this.form.particulars)) {
            this.form.particulars = [{ ledger_head_id: null, drcr: 'dr', amount: 0, opening_balance: '' }];
        } else {
            this.form.particulars.push({ ledger_head_id: null, drcr: 'cr', amount: 0, opening_balance: '' });
        }
    },
    removeElement: function (row) {
        var index = this.form.particulars.indexOf(row);
        this.itemsLedger.splice(index, 1)
        this.form.particulars.splice(index, 1);
    },


    setEntryDate() {
        if (this.form.entry_date == null) {
            let date = this.$converter.todayNP();
            this.form.entry_date = `${date.year}-${date.month.pad(2)}-${date.day.pad(2)}`;
        }

        if (this.form.entry_date.length === 8) {
            this.form.entry_date = this.form.entry_date.substr(0, 4) + '-' + this.form.entry_date[4] + this.form.entry_date[5] + '-' + this.form.entry_date.slice(-2);
        }
    },
    drcrAmount() {
        let sumDr = 0;
        let sumCr = 0;
        this.form.particulars.map(data => {
            if (!['', null, undefined, NaN].includes(data.amount)) {

                if (data.drcr === 'dr') {
                    sumDr += parseFloat(data.amount);
                }
                if (data.drcr === 'cr') {
                    sumCr += parseFloat(data.amount);
                }
            }

        });
        if (sumDr === sumCr && sumDr > 0 && sumCr > 0) this.storeData = false;
        else this.storeData = true;


        if (!this.storeData) {
            return '<strong style="background: #4caf50; border-radius: 5px; color:white;padding: 5px 10px;">' +
                ' Sum DR : ' + sumDr.currency() +
                '</strong>  ' +
                '<strong style="background: #4caf50; border-radius: 5px; color: white;padding: 5px 10px;" >' +
                'Sum CR :' + sumCr.currency() +
                '</strong>';
        } else {
            return '<strong style="background: #ff9800; border-radius: 5px; color:white; padding: 5px 10px;">' +
                ' Sum DR : ' + sumDr.currency() +
                '</strong>  ' +
                '<strong style="background: #ff5252; border-radius: 5px; color: white; padding: 5px 10px;" >' +
                'Sum CR :' + sumCr.currency() +
                '</strong>';
        }


    },
    addRowData(boolean) {
        let sumCr = 0;
        let sumDr = 0;
        this.form.particulars.map(res => {
            if (!['', null, undefined, NaN].includes(res.amount)) {
                if (res.drcr === 'dr') {
                    sumDr += parseFloat(res.amount);
                }
                if (res.drcr === 'cr') {
                    sumCr += parseFloat(res.amount);
                }
            }
        });

        let particularEnd = this.form.particulars[this.form.particulars.length - 1];
        if (sumDr !== sumCr && particularEnd.amount > 0) {
            this.form.particulars.push({ ledger_head_id: null, drcr: 'cr', amount: 0 });
        } else if ((this.form.particulars.length > 1 && sumDr === sumCr)) {
            this.form.particulars.pop();
        }
    },
    getEdited() {
        if (![undefined, null, '', NaN].includes(this.$route.query.id)) {
            this.updateData = true;
            this.form.id = this.$route.query.id;

            let data = this.$route.query.data;

            this.form.narration = data.narration;
            this.form.journal_type_id = data.journal_type_id;
            this.form.voucher_no = data.voucher_no;
            this.form.entry_date = this.$route.query.date;

            this.form.particulars = data.ledger_accounts.map((res, i) => {
                this.itemsLedger[i] = [];
                this.itemsLedger[i].push({ name: res.ledger_head, id: res.ledger_head_id });
                if (res.dr > 0) {

                    return { ledger_head_id: res.ledger_head_id, drcr: 'dr', amount: res.dr };
                } else {
                    return { ledger_head_id: res.ledger_head_id, drcr: 'cr', amount: res.cr };
                }
            });
        } else {
            this.form.particulars = [{ ledger_head_id: '', drcr: 'dr', amount: 0, opening_balance: '' }];
            this.form.narration = '';
            this.form.journal_type_id = '';
            this.form.voucher_no = '';
            this.form.id = '';
            delete this.form.id;
        }
    },
    accountGroupSave() {
        // this.accountGroupForm.parent_id = this.accountGroupForm.parent_id.id;
        this.accountGroupForm.store().then(({ data }) => {
            this.accountGroupFormDialog = false;
            this.entriesA = [{ name: data.data.name, id: data.data.id }]
            this.ledgerForm.account_head_id = data.data.id

        });
    },
    ledgerHeadSearch(e) {
        if (this.searchKey !== null)
            this.$rest.get('/api/ledger-head?search=' + e.target.value)
                .then(({ data }) => {
                    this.itemsLedger[this.searchKey] = [];
                    data.data.map(res => {
                        this.itemsLedger[this.searchKey].push({ name: res.name, id: res.id });
                    });
                }).catch(err => {
                }).finally(() => (this.isLoading = false))
    },
    closingBalance(key) {
        this.$rest.get('/api/opening-balance?ledgerHeadId=' + this.form.particulars[key].ledger_head_id + '&date=' + this.form.entry_date + '&fiscalYearId=1').then(res => {
            this.form.particulars[key].opening_balance = res.data;
        });
    },

}
