import Form from '@/library/Form';

const NepaliDate = require('nepali-date');
const nd = new NepaliDate();

export default {
    searchKey: null,
    form: new Form({
            narration: '',
            entry_date: nd.format('YYYY-MM-DD'),
            particulars: [{ledger_head_id: '', drcr: 'dr', amount: 0, opening_balance:0}],
            journal_type_id: '',
            voucher_no: '',
        },
        '/api/journal-entry'
    ),
    accountGroupForm: new Form({
        name: '',
        description: '',
        company_id: '',
        parent_id: ''
    }, '/api/account-head'),
    accountGroupFormDialog: false,
    headers: [
        {text: 'Journal Type', value: 'journal_type', align: "left", sortable: false},
        {text: 'V No', value: 'voucher_no', align: "left", sortable: false},
        {text: 'Date', value: 'nepali_date', align: "left", sortable: false},
        {text: 'Particular', value: 'particular', align: "left", sortable: false},
        {text: 'LF Id', value: 'lf_id', align: "center"},
        {text: 'Dr.', value: 'dr', align: "center"},
        {text: 'Cr.', value: 'cr', align: "center"},
        {text: 'Actions', value: 'action', sortable: false, align: "right"}
    ],

    ledgerForm: new Form({
        name: '',
        description: '',
        account_head_id: '',
        opening_balance: 0,
        drcr: 'dr',


    }, '/api/ledger-head'),
    searchA: null,
    searchAG: null,

    searchL: null,
    entriesL: [],

    entriesA: [],
    drcr: [{value: 'dr', text: 'DR'}, {value: 'cr', text: 'CR'}],

    ledgerHeads: [], /** Ledger Heads **/

    journalTypes: [], /** Journal Types **/

    accountHeads: [], /** Account Heads **/

    drcrList: [
        {value: 'dr', text: 'DR'},
        {value: 'cr', text: 'CR'}
    ],
    sumCr: '',
    sumDr: '',
    manualSetting: false,
    storeData: false,
    updateData: false,
    itemsLedger: [],

    automaticVoucherNo : '',

    /*items: [
        {
            journal_type: 'Payment Voucher',
            voucher_no: 12,
            napali_date: '2075-12-30',
            lf_id: 20,
            naration: 'Furniture bought company',
            particular: [{
                ledger_head: 'Furniture a/c',
                dr: 2000,
                cr: 0,
            }, {
                ledger_head: 'Furniture a/c',
                dr: 3000,
                cr: 0,
            }, {
                ledger_head: 'Cash a/c',
                dr: 0,
                cr: 2500,
            }, {
                ledger_head: 'Bank a/c',
                dr: 0,
                cr: 2500,
            }
            ]
        },

    ]*/
    /**/
}
